import { useState } from "react";
import dayjs from "dayjs";
import { Bundle, Resource } from "@medplum/fhirtypes";
import useMetriportToast from "./useMetriportToast";
import { useMetriportApi } from "./useMetriportApi";
import { useInterval } from "./use-interval";
// TODO: 2064 Will add to shared when its used by monorepo
import { getConsolidatedWebhook } from "../../../api/consolidated-bundle";
import { capture } from "../../../shared/capture";

type UseGetConsolidated = {
  isQueryingConsolidated: boolean;
  consolidated: Bundle<Resource> | undefined;
  onQueryPatientConsolidated: () => Promise<void>;
};

const POLLING_INTERVAL = dayjs.duration(1, "second").asMilliseconds();
const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function useGetConsolidated({ patientId }: { patientId: string }): UseGetConsolidated {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  const [isQueryingConsolidated, setIsQueryingConsolidated] = useState(false);
  const [consolidatedRequestId, setConsolidatedRequestId] = useState<string | undefined>(undefined);
  const [consolidated, setConsolidated] = useState<Bundle<Resource> | undefined>(undefined);

  async function onQueryPatientConsolidated() {
    try {
      setIsQueryingConsolidated(true);
      const query = await metriportApi.startConsolidatedQuery(
        patientId,
        undefined,
        undefined,
        undefined,
        "json",
        true
      );
      setConsolidatedRequestId(query.requestId);
      toast.info({
        title:
          "Loading the patient's consolidated data. This may take some time depending on how much data is available.",
        duration: EXPLAIN_TOAST_DURATION,
      });
    } catch (error) {
      const msg = "Failed to load patient consolidated data";
      capture.error(msg, {
        extra: { patient: patientId, context: `patient.get.consolidated`, error },
      });
      toast.error({ title: msg });
      setIsQueryingConsolidated(false);
    }
  }

  useInterval(
    async () => {
      const { queries } = await metriportApi.getConsolidatedQueryStatus(patientId);
      // TODO Need to eventually exit polling if the query is not completed
      const allDone = queries?.every(query => query.status === "completed");

      if (allDone && consolidatedRequestId) {
        const webhook = await getConsolidatedWebhook(patientId, consolidatedRequestId);
        const { fileUrl } = webhook;

        if (fileUrl) {
          const bundle = await fetch(fileUrl).then(res => res.json());
          setConsolidated(bundle as Bundle<Resource>);
        } else {
          const msg = "Failed to load patient consolidated data";
          capture.error(msg, {
            extra: { patient: patientId, context: `patient.get.consolidated`, webhook },
          });
          toast.error({ title: msg });
        }
        setIsQueryingConsolidated(false);
        setConsolidatedRequestId(undefined);
      }
    },
    isQueryingConsolidated ? POLLING_INTERVAL : null
  );

  return { isQueryingConsolidated, consolidated, onQueryPatientConsolidated };
}

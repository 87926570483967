import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { PatientDTO } from "@metriport/api-sdk";
import { PatientFacilityMatches } from "../../../../api/patient-matches";
import PatientMatchesTab from "./patient-matches-tab";

export type PatientRecordsProps = Pick<DrawerProps, "placement" | "size" | "onClose"> &
  Partial<Pick<DrawerProps, "isOpen">> & {
    patient: PatientDTO;
  };

export function PatientMatches({
  isOpen,
  patient,
  patientMatches,
  actions,
}: {
  isOpen: boolean;
  patient: PatientDTO | undefined;
  patientMatches: PatientFacilityMatches;
  actions: {
    onClose: () => void;
  };
}) {
  // TODO 2182: ADD DESC TO MAKE MORE CLEAR WHAT THIS COMPONENT IS DOING FOR CX
  const colorMode = useColorModeValue("gray.100", "gray.900");
  return (
    <Drawer onClose={actions.onClose} isOpen={isOpen ?? true} size={"full"}>
      <DrawerOverlay />
      <DrawerContent
        bgColor={"#171923"}
        // https://github.com/chakra-ui/chakra-ui/issues/7091
        motionProps={{ initial: "none", exit: "none" }}
        bg={colorMode}
      >
        <DrawerCloseButton />
        <DrawerHeader></DrawerHeader>
        <DrawerBody pt={4} position={"relative"}>
          <Box
            display={"flex"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="space-between"
            pb={4}
            borderBottom={"1px"}
            borderColor="gray.600"
            mb="4"
          >
            <Heading size="lg">Patient Matches</Heading>
          </Box>
          <PatientMatchesTab patient={patient} matches={patientMatches} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

import { useEffect, useState } from "react";
import useMetriportToast from "./useMetriportToast";
// TODO: 2064 Will add to shared when its used by monorepo
import { getPatientHieOptOut, updatePatientHieOptOut } from "../../../api/patient-opt-out";
import { capture } from "../../../shared/capture";

type UsePatientHieOptOut = {
  patientHieOptOut: boolean;
  toggleHieOptOut: (hieOptOut: boolean) => Promise<void>;
};

export function usePatientHieOptOut({
  patientId,
  isDisabledOnLoad,
}: {
  patientId: string;
  isDisabledOnLoad: boolean;
}): UsePatientHieOptOut {
  const toast = useMetriportToast();
  const [patientHieOptOut, setPatientHieOptOut] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await getPatientHieOptOut(patientId);
        setPatientHieOptOut(resp.hieOptOut);
      } catch (error) {
        const msg = "Error retrieving patient network opt out";
        toast.error({ title: msg });
        capture.message(msg, {
          extra: { context: `patient.hie-opt-out`, error, patientId: patientId },
          level: "error",
        });
      }
    }

    if (!isDisabledOnLoad) fetchData();
  }, []);

  async function toggleHieOptOut(hieOptOut: boolean) {
    try {
      const resp = await updatePatientHieOptOut(patientId, hieOptOut);
      setPatientHieOptOut(hieOptOut);
      toast.success({ title: resp.message });
    } catch (error) {
      const msg = "Error updating patient network opt out";
      toast.error({ title: msg });
      capture.error(msg, {
        extra: { context: `patient.hie-opt-out`, patientId, error },
      });
    }
  }

  return { patientHieOptOut, toggleHieOptOut };
}

import { Box, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DemographicsModal } from "../shared-components/demos-modal";
import { PatientFHIRData } from "../shared-components/fhir-sections";
import { fhirSections } from "../shared-components/fhir-sections/sections";
import { SummaryContent } from "../shared-components/fhir-sections/summaries/content";
import { PatientsHeader } from "../shared-components/header";
import { MrModal } from "../shared-components/mr-modal";
import { PatientForm } from "../shared-components/patient-form";
import { PatientMatches } from "../shared-components/patient-matches";
import { DocumentProgressContainer } from "../shared-components/progress-bar";
import { RawDocuments } from "../shared-components/raw-documents";
import { PatientTabs } from "../shared-components/tabs";
import { usePatientConsolidatedContext } from "../shared-logic/consolidated-context";
import {
  PatientConsolidatedStateActionType,
  PatientQueryParamProps,
  PatientTab,
  getValidPatientTab,
} from "../shared-logic/consolidated-context/reducer";
import { useSharedContext } from "../shared-logic/shared-context";
import { useGetConsolidated } from "../shared-logic/useGetConsolidated";
import { useGetFacilities } from "../shared-logic/useGetFacilties";
import { useGetFilters } from "../shared-logic/useGetFilters";
import { useGetMedicalRecord } from "../shared-logic/useGetMedicalRecord";
import { useGetPatientInfo } from "../shared-logic/useGetPatientInfo";
import { useGetPatientMatches } from "../shared-logic/useGetPatientMatches";
import { usePatientHieOptOut } from "../shared-logic/usePatientHieOptOut";
import { useQueryDocuments } from "../shared-logic/useQueryDocuments";
import { useSubmitPatient } from "../shared-logic/useSubmitPatient";

export type SectionCounts = {
  sectionCounts: {
    reports: number;
    documents: number;
  };
};

export function PatientOverview({
  patientId,
  patientQueryParams,
  isImpersonating,
}: {
  patientId: string;
  patientQueryParams?: PatientQueryParamProps | undefined;
  isImpersonating?: boolean;
}) {
  const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);
  const patientFormDisclosure = useDisclosure();
  const patientMRDisclosure = useDisclosure();
  const patientDemosDisclosure = useDisclosure();
  const patientMatchesDisclosure = useDisclosure();

  const [patientTab, setPatientTab] = useState<PatientTab>(
    getValidPatientTab(patientQueryParams?.patientTab)
  );

  const { sharedState } = useSharedContext();
  const isEhr = !!sharedState.ehrDetails;
  const { patientConsolidatedDispatch, patientConsolidatedState } = usePatientConsolidatedContext();
  const { createOrUpdatePatient, isSubmitting } = useSubmitPatient({
    patientId,
    onComplete: () => patientFormDisclosure.onClose(),
  });
  const { patient, isFetchingPatient, setPatient } = useGetPatientInfo({ patientId });
  const { filters } = useGetFilters();
  const { facilities } = useGetFacilities({ isDisabledOnLoad: isEhr });
  const { patientMatches } = useGetPatientMatches({ patientId, isDisabledOnLoad: isEhr });
  const { patientHieOptOut, toggleHieOptOut } = usePatientHieOptOut({
    patientId,
    isDisabledOnLoad: isEhr,
  });

  const documentsState = patientConsolidatedState[patientId]?.documents;
  const lastDocQueryState = patientConsolidatedState[patientId]?.lastDocQuery;
  const initialDqRunState = patientConsolidatedState[patientId]?.initialDqRun;
  const consolidatedState = patientConsolidatedState[patientId]?.consolidated;

  const { existingMrStatus, isMrDownloading, isAtLeastOneMrDownloading, onDownloadMR } =
    useGetMedicalRecord({ patientId });

  const {
    isQueryingDocuments,
    isListingDocuments,
    isQueryError,
    dqProgress,
    documentListResult,
    initialDocumentListResult,
    lastDocQuery,
    searchDocFilters,
    onQueryPatientDocuments,
    onSearchPatientDocuments,
  } = useQueryDocuments({ patientId });

  const { isQueryingConsolidated, consolidated, onQueryPatientConsolidated } = useGetConsolidated({
    patientId,
  });

  useEffect(() => {
    if (!documentsState && searchDocFilters) {
      onSearchPatientDocuments({ ...searchDocFilters, setFiltersInStorage: false });
    }
  }, [documentsState, searchDocFilters]);

  useEffect(() => {
    async function initDq() {
      if (!initialDqRunState && initialDocumentListResult) {
        if (initialDocumentListResult.documents.length === 0) await onQueryPatientDocuments();
        patientConsolidatedDispatch({
          type: PatientConsolidatedStateActionType.setPatientsInitialDqRun,
          patientId,
          initialDqRun: "initialized",
        });
      }
    }
    initDq();
  }, [initialDqRunState, initialDocumentListResult]);

  useEffect(() => {
    if (
      consolidatedState === undefined &&
      initialDqRunState &&
      !isQueryingDocuments &&
      !isQueryError
    ) {
      onQueryPatientConsolidated();
    }
  }, [consolidatedState, initialDqRunState, isQueryingDocuments, isQueryError]);

  useEffect(() => {
    if (documentListResult) {
      patientConsolidatedDispatch({
        type: PatientConsolidatedStateActionType.setPatientsDocuments,
        patientId,
        documents: documentListResult.documents,
      });
    }
  }, [documentListResult]);

  useEffect(() => {
    if (lastDocQuery) {
      patientConsolidatedDispatch({
        type: PatientConsolidatedStateActionType.setPatientsLastQueryAt,
        patientId,
        lastDocQuery,
      });
    }
  }, [lastDocQuery]);

  useEffect(() => {
    if (consolidated) {
      patientConsolidatedDispatch({
        type: PatientConsolidatedStateActionType.setPatientsConsolidated,
        patientId,
        consolidatedBundle: consolidated,
      });
    }
  }, [consolidated]);

  function setPatientTabAndParams(patientTab: PatientTab) {
    setPatientTab(patientTab);
    patientQueryParams?.setPatientQueryParams({ patientTab });
  }

  return (
    <>
      {/* V1 Going to add Breadcrumbs */}
      <Box p={5} maxW={"2560px"} mx={"auto"} bg={useColorModeValue("gray.100", "gray.900")}>
        <PatientsHeader
          patient={patient}
          isFetchingPatient={isFetchingPatient}
          isQueryingDocuments={isQueryingDocuments || isQueryingConsolidated}
          isAtLeastOneMrDownloading={isAtLeastOneMrDownloading}
          isHieOptedOut={patientHieOptOut}
          selectedTab={patientTab}
          lastDocQuery={lastDocQueryState}
          actions={{
            onQueryPatientDocuments: async () => {
              await onQueryPatientDocuments();
              patientConsolidatedDispatch({
                type: PatientConsolidatedStateActionType.resetPatientsConsolidated,
                patientId,
              });
            },
            onGenerateMedicalRecord: patientMRDisclosure.onOpen,
            onEditPatient: patientFormDisclosure.onOpen,
            onDisplayPatientDemos: patientDemosDisclosure.onOpen,
            onViewPatientMatches: patientMatchesDisclosure.onOpen,
            onSetPatientTab: setPatientTabAndParams,
            toggleHieOptOut,
          }}
          isLimited={isEhr}
          isSticky={isEhr}
          setHeaderHeight={setHeaderHeight}
        />
        {patientTab === "fhir" && (
          <>
            <PatientTabs
              filters={filters}
              isImpersonating={isImpersonating}
              mappedConsolidated={consolidatedState}
              isIFrame={isEhr}
              isLimited={isEhr}
              isStickyHeader={false}
              headerHeight={headerHeight}
            />
            <SummaryContent mappedConsolidated={consolidatedState} />
            <PatientFHIRData
              isImpersonating={isImpersonating}
              fhirSections={fhirSections}
              filters={filters}
              mappedConsolidated={consolidatedState}
              isConsolidatedLoading={isQueryingDocuments || isQueryingConsolidated}
              patientQueryParams={patientQueryParams}
            />
          </>
        )}
        {patientTab === "documents" && (
          <RawDocuments
            searchDocFilters={searchDocFilters}
            documents={documentsState}
            isListingDocuments={isListingDocuments}
            actions={{
              onSearchDocuments: onSearchPatientDocuments,
            }}
          />
        )}
      </Box>
      <DocumentProgressContainer dqProgress={dqProgress} />
      <PatientMatches
        isOpen={patientMatchesDisclosure.isOpen || patientTab === "matches"}
        patient={patient}
        patientMatches={patientMatches}
        actions={{
          onClose: () => {
            setPatientTabAndParams("fhir");
            patientMatchesDisclosure.onClose();
          },
        }}
      />
      <PatientForm
        isOpen={patientFormDisclosure.isOpen}
        selectedPatient={patient}
        facilities={facilities}
        isSubmitting={isSubmitting}
        actions={{
          onCloseForm: patientFormDisclosure.onClose,
          onCreateOrUpdatePatient: async patient => {
            const resp = await createOrUpdatePatient(patient);
            setPatient(resp);
          },
        }}
      />
      <MrModal
        isOpen={patientMRDisclosure.isOpen}
        isDownloading={isMrDownloading}
        existingMrStatus={existingMrStatus}
        actions={{
          onCloseModal: patientMRDisclosure.onClose,
          onDownloadMR,
        }}
      />
      <DemographicsModal
        isOpen={patientDemosDisclosure.isOpen}
        patient={patient}
        actions={{
          onCloseModal: patientDemosDisclosure.onClose,
        }}
      />
    </>
  );
}

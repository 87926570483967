import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
import { useCopyToClipboard } from "usehooks-ts";
import { limitStringLength, StringLimitMode } from "../../../shared/util";
import useMetriportToast from "../shared-logic/useMetriportToast";

export function IdContainer({
  value,
  entity,
  full,
  partialMode,
  partialLength = 15,
  showTooltip = !full,
  fontSize = "lg",
}: {
  value: string | undefined;
  entity: string;
  full?: boolean;
  partialMode?: StringLimitMode;
  partialLength?: number;
  showTooltip?: boolean;
  fontSize?: string;
}) {
  const toast = useMetriportToast();
  const [, copy] = useCopyToClipboard();

  if (!value) return null;

  return (
    <Tooltip label={value} isDisabled={!showTooltip || !value} openDelay={1_000}>
      <Flex
        onClick={() => {
          copy(value);
          toast.success({
            title: `Copied ${entity} ID to clipboard`,
          });
        }}
        cursor={"pointer"}
        alignItems={"center"}
      >
        <Text fontSize={fontSize}>
          {full ? value : limitStringLength(value, partialLength, undefined, partialMode)}
        </Text>
        <Icon ml={2} as={FiCopy} />
      </Flex>
    </Tooltip>
  );
}

import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Actions, useAnalyticsContext } from "@metriport/shared-internal";
import { User } from "@propelauth/react";
import { lowerCase } from "lodash";
import { ReactNode, ReactText } from "react";
import { IconType } from "react-icons";
import { FaNetworkWired, FaRegHospital } from "react-icons/fa";
import { FiChevronDown, FiDollarSign, FiHome, FiMenu, FiSettings, FiUsers } from "react-icons/fi";
import { RiUserHeartLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { useAppContext } from "../contexts/app";
import { useIsUserInSandbox } from "../shared/useIsUserInSandbox";
import SandboxNavbar from "./sandbox";

interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
}

export default function Navbar({
  children,
  signOut,
}: {
  children: ReactNode;
  signOut?: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state } = useAppContext();
  const Analytics = useAnalyticsContext();
  const { isUserInSandbox } = useIsUserInSandbox(state);
  const colorMode = useColorModeValue("gray.100", "gray.900");

  const user = state.user;
  // Gotta load children if no user because of unauthenticated routes, like /feedback/:id
  if (!user) return <>{children}</>;

  return (
    <>
      {state.isImpersonating ? (
        <Alert position={"sticky"} top={0} zIndex={10} status="error">
          <AlertIcon />
          You are currently impersonating a customer.{" "}
        </Alert>
      ) : null}
      <Box minH="100vh" bg={colorMode}>
        <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav
          onOpen={onOpen}
          signOut={() => {
            try {
              Analytics.reset();
            } catch (error) {
              console.error("Error resetting analytics", error);
            } finally {
              signOut && signOut();
            }
          }}
          user={user}
        />
        <Box ml={{ base: 0, md: 60 }}>
          {isUserInSandbox ? (
            <Alert status="warning">
              <AlertIcon />
              Friendly reminder: you're currently in Sandbox mode!
            </Alert>
          ) : null}

          {children}
        </Box>
      </Box>
    </>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const Analytics = useAnalyticsContext();

  const { state } = useAppContext();
  const { isUserInSandbox } = useIsUserInSandbox(state);
  const prefix = isUserInSandbox ? "/sandbox" : "";

  const linkItems: Array<LinkItemProps> = [
    { name: "Home", icon: FiHome, path: "/" },
    { name: "Patients", icon: RiUserHeartLine, path: `${prefix}/patients` },
    { name: "Facilities", icon: FaRegHospital, path: `${prefix}/facilities` },
    { name: "Network Entries", icon: FaNetworkWired, path: `${prefix}/network-entries` },
    { name: "Products & Billing", icon: FiDollarSign, path: "/billing" },
    { name: "Developers", icon: FiSettings, path: `${prefix}/developers` },
    { name: "Users", icon: FiUsers, path: "/users" },
  ];

  return (
    <Box
      transition="1s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image w={150} src="logo.png" alt="Metriport logo" />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box pt={4}>
        {linkItems.map(link => {
          return (
            <NavItem
              onClick={() => {
                Analytics.emit(Actions.navigate, "", { location: lowerCase(link.name) });
              }}
              m={2}
              path={link.path}
              key={link.name}
              icon={link.icon}
            >
              {link.name}
            </NavItem>
          );
        })}
      </Box>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  path: string;
  icon: IconType;
  children: ReactText;
}
const NavItem = ({ path, icon, children, ...rest }: NavItemProps) => {
  return (
    <NavLink to={path} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "#748df0",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </NavLink>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  signOut?: () => void;
  user: User;
}
const MobileNav = ({ onOpen, signOut, user, ...rest }: MobileProps) => {
  const Analytics = useAnalyticsContext();

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Flex display={{ base: "flex", md: "none" }}>
        <Image w={150} src="logo.png" alt="Metriport logo" />
      </Flex>

      <HStack spacing={{ base: "0", md: "6" }}>
        <SandboxNavbar />
        <ColorModeSwitcher justifySelf="flex-end" />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
              <HStack>
                <Avatar size={"sm"} bg={"#748df0"} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm" mb={0}>
                    {user.email}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <NavLink
                onClick={() => Analytics.emit(Actions.navigate, "", { location: "home" })}
                to="/"
              >
                <MenuItem>Home</MenuItem>
              </NavLink>
              <NavLink
                onClick={() => Analytics.emit(Actions.navigate, "", { location: "developers" })}
                to="/developers"
              >
                <MenuItem>Developers</MenuItem>
              </NavLink>
              <MenuDivider />
              <NavLink
                onClick={() => Analytics.emit(Actions.navigate, "", { location: "account" })}
                to="/account"
              >
                <MenuItem>Account</MenuItem>
              </NavLink>
              {/* <MenuItem onClick={account}>Account</MenuItem> */}
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

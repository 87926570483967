import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { NetworkEntry, ResponseMeta } from "@metriport/api-sdk";
import { Actions, Features, useAnalyticsContext } from "@metriport/shared-internal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isApiKeyGenerated } from "../../../domain/api-key";
import { isMapiAccessActive, isMedicalSubscriptionActive } from "../../../domain/customer";
import { capture } from "../../../shared/capture";
import { useAppContext } from "../../contexts/app";
import { enterKeys } from "../../shared/form/Input";
import { useIsUserInSandbox } from "../../shared/useIsUserInSandbox";
import NoApiKey from "../no-api-key";
import NoMapiAccess from "../no-mapi-access";
import { Button } from "../shared-components/button";
import { METRIPORT_PRIMARY } from "../shared-logic/style";
import { useMetriportApi } from "../shared-logic/useMetriportApi";
import useMetriportToast from "../shared-logic/useMetriportToast";
import NetworkEntryGrid, { NetworkEntryGridRef } from "./grid";

export default function NetworkEntries() {
  const { state } = useAppContext();
  const api = useMetriportApi();
  const toast = useMetriportToast();
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState("");
  const [totalCountOfNetworkEntries, setTotalCountOfNetworkEntries] = useState<number | undefined>(
    undefined
  );
  const [tableFromTop, setTableFromTopHeight] = useState(0);
  const Analytics = useAnalyticsContext();
  const gridRef = useRef<NetworkEntryGridRef | null>(null);

  const apiKeyGenerated = useMemo(() => isApiKeyGenerated(state.keyIds), [state.keyIds]);
  const { isUserInSandbox } = useIsUserInSandbox(state);

  const getNetworkEntries = useCallback(
    async (
      pageUrl?: string | undefined,
      localFilters?: string | undefined
    ): Promise<{ networkEntries: NetworkEntry[]; meta: ResponseMeta } | undefined> => {
      try {
        setIsLoading(true);
        if (localFilters) setFilters(localFilters);
        const { networkEntries, meta } = pageUrl
          ? await api.listNetworkEntriesPage(pageUrl)
          : await api.listNetworkEntries({ filter: localFilters, pagination: { count: 50 } });
        if (meta.itemsInTotal != undefined) setTotalCountOfNetworkEntries(meta.itemsInTotal);
        return { networkEntries, meta };
      } catch (error) {
        const msg = "Error retrieving network entries";
        toast.error({ title: msg });
        capture.error(msg, {
          extra: { context: `network-entries.get`, error },
        });
        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    [api]
  );

  useEffect(() => {
    if (isMapiAccessActive(state, isUserInSandbox)) {
      getNetworkEntries();
    }

    const element = document.getElementById("table-container");
    if (element) {
      setTableFromTopHeight(element.getBoundingClientRect().top);
    }
  }, [state]);

  if (state.isLoaded && !apiKeyGenerated) {
    return <NoApiKey />;
  }

  if (
    state.isLoaded &&
    apiKeyGenerated &&
    (!isMedicalSubscriptionActive(state, isUserInSandbox) ||
      !isMapiAccessActive(state, isUserInSandbox))
  ) {
    return <NoMapiAccess />;
  }

  function search() {
    gridRef?.current?.resetSearch(filters);
  }
  function clearFilters() {
    setFilters("");
    gridRef?.current?.resetSearch("");
  }

  return (
    <>
      <Box p={10}>
        <Box px={5}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Heading size="xl">Network Entries</Heading>
          </Flex>
          <Divider my={6} />
          <Flex justifyContent={"space-between"} mb={5}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
                <SearchIcon />
              </InputLeftElement>
              <Input
                minW={"300px"}
                w={"35%"}
                value={filters}
                placeholder="Search for network entries"
                onClick={() => Analytics.emit(Actions.search, Features.networkEntry)}
                onChange={e => setFilters(e.target.value)}
                onKeyUp={key => {
                  if (enterKeys.includes(key.code)) search();
                }}
                _hover={{ borderColor: METRIPORT_PRIMARY }}
                _active={{ borderColor: METRIPORT_PRIMARY }}
                _focus={{ borderColor: METRIPORT_PRIMARY }}
              />
              <Button onClick={search} ml={2}>
                Search
              </Button>
              <Button onClick={clearFilters} ml={2}>
                Clear
              </Button>
            </InputGroup>
          </Flex>
          <Box
            id="table-container"
            style={{ width: "100%", height: `calc(95vh - ${tableFromTop}px)` }}
          >
            <NetworkEntryGrid
              ref={gridRef}
              getNetworkEntries={getNetworkEntries}
              isLoading={isLoading}
              numItemsPerPage={50}
            />
          </Box>
          <Text align={"right"} pt={3}>
            Total amount of network entries: {totalCountOfNetworkEntries}
          </Text>
        </Box>
      </Box>
    </>
  );
}

import { useEffect, useState } from "react";
import useMetriportToast from "./useMetriportToast";
// TODO: 2064 Will add to shared when its used by monorepo
import { getPatientFacilityMatches, PatientFacilityMatches } from "../../../api/patient-matches";
import { capture } from "../../../shared/capture";

type UseGetPatientMatches = {
  patientMatches: PatientFacilityMatches;
};

export function useGetPatientMatches({
  patientId,
  isDisabledOnLoad,
}: {
  patientId: string;
  isDisabledOnLoad: boolean;
}): UseGetPatientMatches {
  const toast = useMetriportToast();
  const [patientMatches, setPatientMatches] = useState<PatientFacilityMatches>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const matches = await getPatientFacilityMatches(patientId);
        setPatientMatches(matches);
      } catch (error) {
        const msg = "Error retrieving patient matches";
        toast.error({ title: msg });
        capture.message(msg, {
          extra: { context: `patient.matches`, patientId, error },
          level: "error",
        });
      }
    }

    if (!isDisabledOnLoad) fetchData();
  }, [patientId]);

  return { patientMatches };
}

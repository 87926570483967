import { Facility } from "@metriport/api-sdk";
import { useEffect, useState } from "react";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";

type UseGetFacilities = {
  facilities: Facility[];
};

export function useGetFacilities({
  isDisabledOnLoad,
}: {
  isDisabledOnLoad: boolean;
}): UseGetFacilities {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();
  const [facilities, setFacilities] = useState<Facility[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const facilities = await metriportApi.listFacilities();
        setFacilities(facilities);
      } catch (error) {
        const msg = "Failed to load facilities";
        toast.error({ title: msg });
        capture.error(msg, {
          extra: { context: `facilities.get`, error },
        });
      }
    }

    if (!isDisabledOnLoad) fetchData();
  }, []);

  return { facilities };
}

import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Tag,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { DocumentReference, Resource } from "@medplum/fhirtypes";
import { SearchDocsFilters } from "../shared-logic/useQueryDocuments";
import { Button } from "./button";
import { buildDocumentTableData } from "./fhir-sections/documents/table-data";
import { NoPatientData } from "./no-data";
import { SearchModal } from "./search-modal";
import { TableGrid } from "./table-grid";

export function RawDocuments({
  searchDocFilters,
  documents,
  isListingDocuments,
  actions,
}: {
  searchDocFilters: SearchDocsFilters | undefined;
  documents: DocumentReference[] | undefined;
  isListingDocuments: boolean;
  actions: {
    onSearchDocuments: (filters: SearchDocsFilters) => Promise<void>;
  };
}) {
  const searchDocumentsDisclosure = useDisclosure();

  const mappedDocumentReferences: { [resourceId: string]: Resource } = {};

  documents?.forEach(doc => {
    if (doc.id) {
      mappedDocumentReferences[doc.id] = doc;
    }
  });

  const { columnDefs, rowData } = buildDocumentTableData({
    bundle: {
      ["DocumentReference"]: mappedDocumentReferences,
    },
    tableFilters: undefined,
    noActions: false,
  });

  return (
    <Box mt={4}>
      <Box
        w={"100%"}
        borderRadius={10}
        mb={5}
        bg={useColorModeValue("white", "gray.800")}
        px={7}
        py={5}
        border="solid"
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Flex mb={4} flexDirection={"row"} justifyContent={"space-between"}>
          <Heading>Raw Documents</Heading>
          <Flex flexDirection={{ base: "column-reverse", xl: "row" }}>
            {searchDocFilters && <SelectedFilters filters={searchDocFilters} />}
            <Button mb={{ base: "2", xl: 0 }} onClick={searchDocumentsDisclosure.onOpen}>
              Filters
            </Button>
          </Flex>
        </Flex>
        {documents && documents.length === 0 ? (
          <Skeleton height={"full"} isLoaded={!isListingDocuments}>
            <NoPatientData />
          </Skeleton>
        ) : (
          <Box position={"relative"}>
            <Skeleton height={"full"} isLoaded={documents !== undefined && !isListingDocuments}>
              <TableGrid sectionTitle={"Documents"} columnDefs={columnDefs} rowData={rowData} />
            </Skeleton>
          </Box>
        )}
      </Box>
      <SearchModal
        isOpen={searchDocumentsDisclosure.isOpen}
        onClose={searchDocumentsDisclosure.onClose}
        onSearchDocuments={actions.onSearchDocuments}
        isFetchingDocuments={isListingDocuments}
        searchDocFilters={searchDocFilters}
      />
    </Box>
  );
}

function SelectedFilters({ filters }: { filters: SearchDocsFilters }) {
  return (
    <>
      {filters.dateFrom && <FilterTag filter={`Date From: ${filters.dateFrom}`} />}
      {filters.dateTo && <FilterTag filter={`Date To: ${filters.dateTo}`} />}
      {filters.content && <FilterTag filter={`Content: ${filters.content.substring(0, 10)}...`} />}
    </>
  );
}

function FilterTag({ filter }: { filter: string }) {
  return (
    <Tag
      variant="subtle"
      colorScheme="purple"
      size={"lg"}
      mr={{ base: 0, xl: 2 }}
      mb={{ base: 2, xl: 0 }}
    >
      {filter}
    </Tag>
  );
}

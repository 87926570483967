import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { PatientDTO } from "@metriport/api-sdk";
import { useEffect, useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiInfo } from "react-icons/fi";
import { PatientTab } from "../shared-logic/consolidated-context/reducer";
import { METRIPORT_PRIMARY } from "../shared-logic/style";
import { Button as MetriportButton } from "./button";
import { IdContainer } from "./id-copy-container";

type HeaderActions = {
  onQueryPatientDocuments: () => Promise<void>;
  onGenerateMedicalRecord: () => void;
  onEditPatient: () => void;
  onDisplayPatientDemos: () => void;
  onViewPatientMatches: () => void;
  onSetPatientTab: (tab: PatientTab) => void;
  toggleHieOptOut: (hieOptOut: boolean) => Promise<void>;
};

type HeaderParams = {
  patient: PatientDTO | undefined;
  isFetchingPatient: boolean;
  isQueryingDocuments: boolean;
  isAtLeastOneMrDownloading: boolean;
  isHieOptedOut: boolean;
  selectedTab: PatientTab;
  lastDocQuery: string | undefined;
  actions: HeaderActions;
  isLimited?: boolean;
  isSticky?: boolean;
  setHeaderHeight: (height: number) => void;
};

type LimitedHeaderActions = Omit<
  HeaderActions,
  "onEditPatient" | "onViewPatientMatches" | "toggleHieOptOut"
>;
type LimitedHeaderParams = Omit<HeaderParams, "isHieOptedOut" | "actions"> & {
  actions: LimitedHeaderActions;
};

export function PatientsHeader(params: HeaderParams) {
  if (params.isLimited) {
    return <LimitedPatientHeader {...params} />;
  }
  return <FullPatientsHeader {...params} />;
}

function FullPatientsHeader({
  patient,
  isQueryingDocuments,
  isFetchingPatient,
  isAtLeastOneMrDownloading,
  isHieOptedOut,
  selectedTab,
  lastDocQuery,
  actions,
  isSticky = false,
  setHeaderHeight,
}: HeaderParams) {
  const { elementRef, updateHeight } = getRefAndUpdate(setHeaderHeight);
  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  return (
    <Box
      ref={elementRef}
      bg={useColorModeValue("gray.100", "gray.900")}
      top={0}
      position={isSticky ? "sticky" : "relative"}
      zIndex={isSticky ? 2 : 0}
    >
      <LastQueried lastDocQuery={lastDocQuery} />
      <Box
        bg={useColorModeValue("white", "gray.800")}
        border={"solid"}
        borderRadius={10}
        borderColor={useColorModeValue("gray.200", "gray.700")}
        px={7}
        py={5}
      >
        <Flex flexDirection={{ base: "column", xl: "row" }} justifyContent={"space-between"}>
          <Flex
            flexDirection={{ base: "row", xl: "column" }}
            justifyContent={{ base: "space-between", xl: "flex-start" }}
          >
            <PatientInfo patient={patient} isLoading={isFetchingPatient} actions={actions} />
            <PatientDemo patient={patient} isLoading={isFetchingPatient} actions={actions} />
          </Flex>
          <Flex justifyContent={{ base: "space-between", xl: "flex-end" }}>
            <PatientButtonActions
              isAtLeastOneMrDownloading={isAtLeastOneMrDownloading}
              isQueryingDocuments={isQueryingDocuments}
              actions={actions}
            />
            <PatientMenuActionsFull isHieOptedOut={isHieOptedOut} actions={actions} />
          </Flex>
        </Flex>
        <Divider borderColor={useColorModeValue("gray.300", "gray.600")} my={5} />
        <PatientTabs selectedTab={selectedTab} actions={actions} />
      </Box>
    </Box>
  );
}

function LimitedPatientHeader({
  patient,
  isFetchingPatient,
  selectedTab,
  actions,
  isSticky = false,
  setHeaderHeight,
}: LimitedHeaderParams) {
  const { elementRef, updateHeight } = getRefAndUpdate(setHeaderHeight);
  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  return (
    <Box
      ref={elementRef}
      bg={useColorModeValue("gray.100", "gray.900")}
      top={0}
      position={isSticky ? "sticky" : "relative"}
      zIndex={isSticky ? 2 : 0}
    >
      <Box
        bg={useColorModeValue("white", "gray.800")}
        border={"solid"}
        borderRadius={10}
        borderColor={useColorModeValue("gray.200", "gray.700")}
        px={5}
        py={3}
      >
        <Flex flexDirection={{ base: "column", xl: "row" }} justifyContent={"space-between"}>
          <Flex
            flexDirection={"row"}
            justifyContent={{ base: "space-between", xl: "flex-start" }}
            mb={2}
          >
            <PatientInfo isLimited={true} patient={patient} isLoading={isFetchingPatient} />
            <PatientDemo
              isLimited={true}
              patient={patient}
              isLoading={isFetchingPatient}
              actions={actions}
            />
          </Flex>
          <Flex justifyContent={{ base: "space-between", xl: "flex-end" }}>
            <PatientTabs isLimited={true} selectedTab={selectedTab} actions={actions} />
            <PatientMenuActionsLimited actions={actions} />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

function LastQueried({ lastDocQuery }: { lastDocQuery: string | undefined }) {
  return (
    <Box
      display={{ base: "none", "2xl": "block" }}
      position={"absolute"}
      top={0}
      marginLeft={"auto"}
      marginRight={"auto"}
      left={0}
      right={0}
      border={"solid"}
      borderTop={0}
      borderBottomRadius={10}
      borderColor={METRIPORT_PRIMARY}
      width={"fit-content"}
      paddingX={4}
      paddingY={2}
    >
      <Text color={METRIPORT_PRIMARY}>Last Queried: {lastDocQuery ?? "-"}</Text>
    </Box>
  );
}

function PatientInfo({
  isLimited = false,
  patient,
  isLoading,
  actions,
}: {
  isLimited?: boolean;
  patient: PatientDTO | undefined;
  isLoading: boolean;
  actions?: Pick<HeaderActions, "onEditPatient">;
}) {
  const patientName = `${patient?.firstName} ${patient?.lastName}`;
  return (
    <Skeleton display={"flex"} flexDirection={"row"} alignItems="center" isLoaded={!isLoading}>
      <Text fontSize={isLimited ? "xs" : "2xl"} fontWeight={"bold"}>
        {patientName}
      </Text>
      {actions && (
        <IconButton
          onClick={actions.onEditPatient}
          bg={"transparent"}
          _hover={{ color: "#879ced" }}
          aria-label="Edit Patient"
          icon={<EditIcon />}
        />
      )}
    </Skeleton>
  );
}

function PatientDemo({
  isLimited = false,
  patient,
  isLoading,
  actions,
}: {
  isLimited?: boolean;
  patient: PatientDTO | undefined;
  isLoading: boolean;
  actions: Pick<HeaderActions, "onDisplayPatientDemos">;
}) {
  const demoFields: Array<{ label: string; value: string | undefined }> = [
    { label: "id", value: patient?.id },
    { label: "dob", value: patient?.dob },
    { label: "gender", value: patient?.genderAtBirth },
  ];
  return (
    <Skeleton
      display={"flex"}
      flexDirection={"row"}
      alignItems="center"
      isLoaded={!isLoading}
      ml={isLimited ? 2 : 0}
    >
      {demoFields.map(({ label, value }, i) => (
        <Box
          ps={i === 0 ? 0 : 2}
          pe={2}
          borderRight={"solid"}
          borderColor={useColorModeValue("gray.300", "gray.600")}
          key={i}
        >
          <Box alignItems="center">
            {label === "id" ? (
              <IdContainer fontSize={isLimited ? "xs" : "md"} value={value} entity={"Patient"} />
            ) : (
              <Text fontSize={isLimited ? "xs" : "md"}>{value}</Text>
            )}
          </Box>
        </Box>
      ))}
      <Icon ml={2} cursor={"pointer"} onClick={actions.onDisplayPatientDemos} as={FiInfo} />
    </Skeleton>
  );
}

function PatientTabs({
  isLimited = false,
  selectedTab,
  actions,
}: {
  isLimited?: boolean;
  selectedTab: PatientTab;
  actions: Pick<HeaderActions, "onSetPatientTab">;
}) {
  return (
    <Flex justifyContent={"center"}>
      <MetriportButton
        mr={5}
        size={isLimited ? "xs" : "md"}
        onClick={() => actions.onSetPatientTab("fhir")}
        variant={selectedTab === "fhir" ? "solid" : "outline"}
      >
        View Summary
      </MetriportButton>
      <MetriportButton
        mr={5}
        size={isLimited ? "xs" : "md"}
        onClick={() => actions.onSetPatientTab("documents")}
        variant={selectedTab === "documents" ? "solid" : "outline"}
      >
        View Raw Documents
      </MetriportButton>
    </Flex>
  );
}

function PatientButtonActions({
  isAtLeastOneMrDownloading,
  isQueryingDocuments,
  actions,
}: {
  isAtLeastOneMrDownloading: boolean;
  isQueryingDocuments: boolean;
  actions: Pick<HeaderActions, "onGenerateMedicalRecord" | "onQueryPatientDocuments">;
}) {
  return (
    <Flex justifyContent={"center"}>
      <MetriportButton
        mr={5}
        size={"md"}
        isLoading={isAtLeastOneMrDownloading}
        onClick={actions.onGenerateMedicalRecord}
      >
        Generate Medical Record
      </MetriportButton>
      <MetriportButton
        mr={5}
        size={"md"}
        isLoading={isQueryingDocuments}
        onClick={actions.onQueryPatientDocuments}
        variant={"outline"}
      >
        Request Patient Data
      </MetriportButton>
    </Flex>
  );
}

function PatientMenuActionsFull({
  isHieOptedOut,
  actions,
}: {
  isHieOptedOut?: boolean;
  actions: Pick<HeaderActions, "onViewPatientMatches" | "onSetPatientTab" | "toggleHieOptOut">;
}) {
  const bg = useColorModeValue("gray.50", "gray.800");
  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<BsThreeDotsVertical />}
        variant="outline"
      />
      <MenuList bg={bg}>
        <MenuItem
          onClick={() => {
            actions.onSetPatientTab("matches");
            actions.onViewPatientMatches();
          }}
          bg={bg}
        >
          View Patient Matches
        </MenuItem>
        <MenuItem onClick={() => actions.toggleHieOptOut(!isHieOptedOut)} bg={bg}>
          {isHieOptedOut ? "Opt In to" : "Opt Out"} of Networks
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function PatientMenuActionsLimited({
  actions,
}: {
  actions: Pick<HeaderActions, "onGenerateMedicalRecord" | "onQueryPatientDocuments">;
}) {
  const bg = useColorModeValue("gray.50", "gray.800");
  return (
    <Menu placement="bottom-end">
      <MenuButton
        size={"xs"}
        as={IconButton}
        aria-label="Options"
        icon={<BsThreeDotsVertical />}
        variant="outline"
      />
      <MenuList bg={bg}>
        <MenuItem onClick={actions.onQueryPatientDocuments} bg={bg}>
          Request Patient Data
        </MenuItem>
        <MenuItem onClick={actions.onGenerateMedicalRecord} bg={bg}>
          Generate Medical Record
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function getRefAndUpdate(setHeaderHeight: (height: number) => void): {
  elementRef: React.RefObject<HTMLDivElement>;
  updateHeight: () => void;
} {
  const elementRef = useRef<HTMLDivElement>(null);
  const updateHeight = () => {
    if (elementRef.current) {
      setHeaderHeight(elementRef.current.offsetHeight);
    }
  };
  return { elementRef, updateHeight };
}
